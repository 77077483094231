import { getSystemPrefAll } from 'store/auth';
import { APP_CONSTANS } from '../../shared/constants/index';
import { getValueByCode } from '../../shared/utils/getValueByCode';
import { logging } from '../../shared/utils/logging';
import { setSessionLifeTime } from '../../shared/utils/setSessionLifeTime';
import { signOutProcess } from '../../shared/utils/signout';
import { ACTION_TYPES as Auth } from '../auth/types';
import {
  getAllComplications,
  getAllComplicationsChildren,
  getCommentsInFulfilment,
  getCurrentFulfilment,
  getCurrentJobCheckPoints,
  getCurrentJobDescription,
  getFilesInFulfilment,
  getFulfilmentRequests,
  getFulfilments,
  getOrders,
  setCurrentJobActiveCheckPoint,
  setCurrentJobId,
} from '../jobs/actions';
import { ACTION_TYPES as Jobs } from '../jobs/types';
import { setMessage } from '../messaging';
import {
  getOnboarding,
  getPurchaseMethodCount,
  getPurchaseMethods,
  getUser,
  getUserAccount,
  getUserAccountPrefs,
  getUserAddresses,
  getUserClaims,
  getUserCountries,
  getUserPrefs,
  getUserRegions,
  getUserRequests,
  showOnboard,
} from '../user/actions';
import { ACTION_TYPES as User } from '../user/types';
import { message } from 'antd';

const RETRY_REQUESTS = {
  GET_USER: { retry: 0, action: getUser() },
  GET_USER_CLAIMS: { retry: 0, action: getUserClaims() },
  GET_USER_PREFS: { retry: 0, action: getUserPrefs() },
  GET_USER_ACCOUNT: { retry: 0, action: getUserAccount() },
  GET_USER_ONBOARD: { retry: 0, action: getOnboarding() },
  GET_USER_ACCOUNT_PREFS: { retry: 0, action: getUserAccountPrefs() },
  GET_USER_ADDRESSES: { retry: 0, action: getUserAddresses() },
  GET_USER_REQUESTS: { retry: 0, action: getUserRequests() },
  GET_USER_COUNTRIES: { retry: 0, action: getUserCountries() },
  GET_USER_REGIONS: { retry: 0, action: getUserRegions() },
  GET_JOBS_ORDERS: { retry: 0, action: getOrders() },
  GET_JOBS_FULFILMENTS: { retry: 0, action: getFulfilments() },
  GET_JOBS_CURRENT_FULFILMENT: {
    retry: 0,
    action: getCurrentFulfilment(),
  },
  GET_JOBS_CURRENT_DESCRIPTION: {
    retry: 0,
    action: getCurrentJobDescription(),
  },
  GET_JOBS_FILES_IN_FULFILMENT: {
    retry: 0,
    action: getFilesInFulfilment(),
  },
  GET_JOBS_COMMENTS_IN_FULFILMENT: {
    retry: 0,
    action: getCommentsInFulfilment(),
  },
  GET_JOBS_REQUESTS_LIST: {
    retry: 0,
    action: getFulfilmentRequests(),
  },
  GET_ALL_COMPLICATIONS: { retry: 0, action: getAllComplications() },
  GET_ALL_COMPLICATIONS_CHILDREN: {
    retry: 0,
    action: getAllComplicationsChildren(),
  },
};

const httpGlobalFetch = (store) => (next) => (action) => {
  const baseUrl = APP_CONSTANS.BASE_API_URL;
  const newBaseUrl = APP_CONSTANS.NEW_BASE_API_URL;
  const paymentApiUrl =  APP_CONSTANS.PAYMENT_API_URL;
  // const params = {} // variables from .env

  const handleError = (err, errCode, requestType, isSignout = true) => {
    const type = APP_CONSTANS.MESSAGING.NETWORK_ERROR.TYPE;
    const status = APP_CONSTANS.MESSAGING.STATUS.ERROR;
    const contetnt = APP_CONSTANS.MESSAGING.NETWORK_ERROR[status];
    if (RETRY_REQUESTS.hasOwnProperty(requestType) && RETRY_REQUESTS[requestType]?.retry < 10) {
      RETRY_REQUESTS[requestType].retry += 1;
      store.dispatch(RETRY_REQUESTS[requestType].action);
    } else if (RETRY_REQUESTS[requestType]?.retry === 10) {
      store.dispatch(setMessage(type, status, contetnt));
      logging(`request_type_${requestType}`);
      console.error('Error:', err);
      console.error('Code:', errCode);
      if (isSignout) {
        setTimeout(() => signOutProcess(), 1000);
      }
    }
  };

  switch (action.type) {
    // GET requests --------------------------------------------------

    case User.GET_USER:
      return next({
        type: User.GET_USER,
        payload: fetch(`${baseUrl}/auth/user`, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            setTimeout(() => store.dispatch(getUserClaims()), 10);
            setSessionLifeTime();
            return response.json();
          })
          .then((data) => {
            localStorage.setItem(
              'user_name',
              `${data.first_name} ${data.last_name}`,
            );
            localStorage.setItem('user_email', data.email);
            return data;
          })
          .catch((err) => handleError(err, '001', 'GET_USER')),
      });

    case User.GET_USER_CLAIMS:
      return next({
        type: User.GET_USER_CLAIMS,
        payload: fetch(`${baseUrl}/auth/claims`, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            setTimeout(() => store.dispatch(getUserPrefs()), 100);
            setSessionLifeTime();
            return response.json();
          })
          .catch((err) => handleError(err, '002', 'GET_USER_CLAIMS')),
      });

    case User.GET_USER_PREFS: {
      const id = store.getState().user.user.id;
      if (!id) {
        setTimeout(() => store.dispatch(getUserPrefs()), 10);
        return;
      }
      return next({
        type: User.GET_USER_PREFS,
        payload: fetch(`${baseUrl}/user_preferences/${id}`, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            setTimeout(() => store.dispatch(getUserAccount()), 100);
            setTimeout(() => store.dispatch(getUserAccountPrefs()), 100);
            setTimeout(() => store.dispatch(getSystemPrefAll()), 150);
            setSessionLifeTime();
            return response.json();
          })
          .catch((err) => handleError(err, '003', 'GET_USER_PREFS')),
      });
    }

    case User.GET_USER_ACCOUNT: {
      const id = store.getState().user.userClaims.aud;
      if (!id) {
        setTimeout(() => store.dispatch(getUserAccount()), 100);
        return;
      }
      return next({
        type: User.GET_USER_ACCOUNT,
        payload: fetch(`${baseUrl}/accounts/${id}`, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            setTimeout(() => store.dispatch(getOnboarding()), 100);
            setSessionLifeTime();
            return response.json();
          })
          .catch((err) => handleError(err, '004', 'GET_USER_ACCOUNT')),
      });
    }

    case User.GET_USER_ONBOARD: {
      return next({
        type: User.GET_USER_ONBOARD,
        payload: fetch(`${baseUrl}/onboard`, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            setSessionLifeTime();
            return response.json();
          })
          .catch((err) => handleError(err, '005', 'GET_USER_ONBOARD')),
      });
    }

    case User.SET_USER_SHOW_ONBOARD: {
      const userId = store.getState().user.user.id;
      const onBoardId = action.payload;
      const isSuperuser =
        store.getState().user.userClaims?.type === APP_CONSTANS.USERS.SUPERUSER;
      if (isSuperuser) {
        return store.dispatch(showOnboard(false));
      }
      return next({
        type: User.SET_USER_SHOW_ONBOARD,
        payload: fetch(`${baseUrl}/onboard/${userId}/${onBoardId}`, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        }).then((response) => {
          setSessionLifeTime();
          if (response.status === 404) {
            store.dispatch(showOnboard(true));
          } else {
            store.dispatch(showOnboard(false));
          }
          return response.json();
        }),
        // TODO - Remome this comment and fix 404 response on back-end side
        // .catch(err => handleError(err, '006', 'SET_USER_SHOW_ONBOARD'))
      });
    }

    case User.GET_USER_ACCOUNT_PREFS: {
      const id = store.getState().user.userClaims.aud;
      if (!id) {
        setTimeout(() => store.dispatch(getUserAccount()), 100);
        return;
      }
      return next({
        type: User.GET_USER_ACCOUNT_PREFS,
        payload: fetch(`${baseUrl}/account_preferences/${id}`, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            setTimeout(() => store.dispatch(getUserAddresses()), 100);
            setTimeout(() => store.dispatch(getUserRequests()), 100);
            setSessionLifeTime();
            return response.json();
          })
          .catch((err) => handleError(err, '007', 'GET_USER_ACCOUNT_PREFS')),
      });
    }

    case User.GET_USER_ADDRESSES: {
      const url = new URL(baseUrl + '/user_addresses');
      const params = {
        user_id: store.getState().user.user.id,
      };
      if (!params.user_id) {
        setTimeout(() => store.dispatch(getUserAddresses()), 100);
        return;
      }
      url.search = new URLSearchParams(params).toString();
      return next({
        type: User.GET_USER_ADDRESSES,
        payload: fetch(url, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            setTimeout(() => store.dispatch(getUserCountries()), 100);
            setSessionLifeTime();
            return response.json();
          })
          .catch((err) => handleError(err, '008', 'GET_USER_ADDRESSES')),
      });
    }

    case User.GET_USER_REQUESTS: {
      const url = new URL(baseUrl + '/user_requests');
      const params = {
        user_id: store.getState().user.user.id,
      };
      if (!params.user_id) {
        setTimeout(() => store.dispatch(getUserRequests()), 100);
        return;
      }
      url.search = new URLSearchParams(params).toString();
      return next({
        type: User.GET_USER_REQUESTS,
        payload: fetch(url, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            setSessionLifeTime();
            return response.json();
          })
          .catch((err) => handleError(err, '009', 'GET_USER_REQUESTS')),
      });
    }

    case User.GET_USER_COUNTRIES: {
      const url = new URL(baseUrl + '/countries');
      return next({
        type: User.GET_USER_COUNTRIES,
        payload: fetch(url, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            setTimeout(() => store.dispatch(getUserRegions()), 100);
            setSessionLifeTime();
            return response.json();
          })
          .catch((err) => handleError(err, '010', 'GET_USER_COUNTRIES')),
      });
    }

    case User.GET_USER_REGIONS: {
      const countryCodeAction = action.payload;
      const currentUserAddresses = store.getState().user.userAddresses;
      if (!currentUserAddresses) {
        setTimeout(() => store.dispatch(getUserRegions()), 100);
        return;
      }
      const countryCode = countryCodeAction
        ? countryCodeAction
        : currentUserAddresses[0].country;
      const url = new URL(
        baseUrl + `/country_regions?country_code=${countryCode}`,
      );
      return next({
        type: User.GET_USER_REGIONS,
        payload: fetch(url, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            setSessionLifeTime();
            return response.json();
          })
          .catch((err) => handleError(err, '011', 'GET_USER_REGIONS')),
      });
    }

    case User.GET_USER_PURCHASE_METHODS: {
      const accountId = store.getState().user.user.account_id;
      const url = new URL(
        paymentApiUrl + `/purchase_methods?account_ids=${accountId}&capacity=L`,
      );
      return next({
        type: User.GET_USER_PURCHASE_METHODS,
        payload: fetch(url, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            setSessionLifeTime();
            return response.json();
          })
          .catch((err) => handleError(err, '011_Next', 'GET_USER_PURCHASE_METHODS')),
      });
    }

    case User.GET_USER_PURCHASE_METHODS_COUNT: {
      const accountId = store.getState().user.user.account_id;
      const url = new URL(
        paymentApiUrl + `/purchase_methods/count?account_ids=${accountId}`,
      );
      return next({
        type: User.GET_USER_PURCHASE_METHODS_COUNT,
        payload: fetch(url, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            setSessionLifeTime();
            return response.json();
          })
          .catch((err) => handleError(err, '011_Next', 'GET_USER_PURCHASE_METHODS_COUNT')),
      });
    }

    case User.GET_USER_PURCHASE_TARGETS: {
      const url = new URL(
        paymentApiUrl + `/purchase_targets?is_active=true&is_linked=true`
      );
      return next({
        type: User.GET_USER_PURCHASE_TARGETS,
        payload: fetch(url, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            setSessionLifeTime();
            return response.json();
          })
          .catch((err) => handleError(err, '011_Next', 'GET_USER_PURCHASE_TARGETS')),
      });
    }

    case Jobs.GET_JOBS_ORDERS: {
      const isSuperuser =
        store.getState().user.userClaims?.type ===
          APP_CONSTANS.USERS.SUPERUSER ||
        store.getState().user.userClaims?.type === APP_CONSTANS.USERS.SUPPORT;
      const isGeolocationAllowed = store.getState().user.isGeolocationAllowed;
      const url = new URL(baseUrl + '/orders');
      const locationPreference = getValueByCode(
        store.getState().user.userPrefs,
        APP_CONSTANS.PHOTOGRAPHER_PROPS.LOCATION_FROM.CODE,
      );
      if (
        !store.getState().user.userClaims.prefs.geo_point_radius ||
        !store.getState().user.user.id
      ) {
        setTimeout(() => store.dispatch(getOrders()), 1000);
        return;
      }
      if (
        !store.getState().user.userAddresses[0].lat ||
        !store.getState().user.userAddresses[0].lon
      ) {
        message.error({content: 'Address location undefined. Please update you address in Profile.', key: 'addressError'})
      }
      const userRadius = getValueByCode(
        store.getState().user.userPrefs,
        APP_CONSTANS.PHOTOGRAPHER_PROPS.JOB_LOOKUP_RADIUS,
      );
      const params = {
        location_lat: isSuperuser
          ? ''
          : store.getState().user.userAddresses[0].lat,
        location_lng: isSuperuser
          ? ''
          : store.getState().user.userAddresses[0].lon,
        radius: isSuperuser
          ? ''
          : userRadius ? userRadius : store.getState().user.userClaims.prefs.geo_point_radius || 30,
        // limit: 20,
        photographer_id: isSuperuser ? '' : store.getState().user.user.id,
        state_ids: APP_CONSTANS.ORDER_STATUSES_GUID_BY_CODE.PUBLISHED_ORDER,
      };
      const lat = store.getState().user.currentPosition.latitude;
      const lng = store.getState().user.currentPosition.longitude;
      if (
        locationPreference ===
          APP_CONSTANS.PHOTOGRAPHER_PROPS.LOCATION_FROM.LOCATION &&
        isGeolocationAllowed &&
        !isSuperuser &&
        lat &&
        lng
      ) {
        params.location_lat = lat;
        params.location_lng = lng;
      }
      url.search = new URLSearchParams(params).toString();
      return next({
        type: Jobs.GET_JOBS_ORDERS,
        payload: fetch(url, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            setSessionLifeTime();
            return response.json();
          })
          .catch((err) => handleError(err, '012', 'GET_JOBS_ORDERS')),
      });
    }

    case Jobs.GET_JOBS_FULFILMENTS: {
      // const filterQUID = action.payload;
      const url = new URL(baseUrl + '/fulfilments/counts');
      const params = {
        user_id: store.getState().user.user.id,
        // limit: 20,
      };
      if (!params.user_id) {
        setTimeout(() => store.dispatch(getFulfilments()), 100);
        return;
      }
      // if (filterQUID && filterQUID.state_ids) {
      //     params.state_ids = filterQUID.state_ids;
      // }
      url.search = new URLSearchParams(params).toString();
      return next({
        type: Jobs.GET_JOBS_FULFILMENTS,
        payload: fetch(url, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            setSessionLifeTime();
            return response.json();
          })
          .catch((err) => handleError(err, '013', 'GET_JOBS_FULFILMENTS')),
      });
    }

    case Jobs.GET_JOBS_CURRENT_ORDER: {
      const id = action.payload;
      const url = new URL(baseUrl + `/orders/${id}`);
      return next({
        type: Jobs.GET_JOBS_CURRENT_ORDER,
        payload: fetch(url, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            setSessionLifeTime();
            return response.json();
          })
          .catch((err) => handleError(err, '014a', 'GET_JOBS_CURRENT_ORDER')),
      });
    }

    case Jobs.GET_JOBS_CURRENT_JOB: {
      const id = store.getState().jobs.currentJobId;
      const url = new URL(baseUrl + `/orders/${id}`);
      const history = action.payload;
      return next({
        type: Jobs.GET_JOBS_CURRENT_JOB,
        payload: fetch(url, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            if (response.status >= 400) {
              const type = APP_CONSTANS.MESSAGING.UNAVAILABLE_ORDER.TYPE;
              const status = APP_CONSTANS.MESSAGING.STATUS.OK;
              const contetnt = APP_CONSTANS.MESSAGING.UNAVAILABLE_ORDER[status];
              setTimeout(() => {
                store.dispatch(setMessage(type, status, contetnt));
              }, 500);
              history.push('/');
              return;
            }
            setSessionLifeTime();
            return response.json();
          })
          .catch((err) => handleError(err, '014', 'GET_JOBS_CURRENT_JOB')),
      });
    }

    case Jobs.GET_JOBS_CURRENT_FULFILMENT: {
      const id = store.getState().jobs.currentJobId;
      const url = new URL(baseUrl + `/fulfilments/${id}`);
      return next({
        type: Jobs.GET_JOBS_CURRENT_FULFILMENT,
        payload: fetch(url, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            setSessionLifeTime();

            return response.json();
          })
          .catch((err) =>
            handleError(err, '015', 'GET_JOBS_CURRENT_FULFILMENT'),
          ),
      });
    }

    case Jobs.GET_JOBS_CURRENT_DESCRIPTION: {
      const id = store.getState().jobs.currentJobDescriptionID;
      const url = new URL(baseUrl + `/orders/${id}/description`);
      return next({
        type: Jobs.GET_JOBS_CURRENT_DESCRIPTION,
        payload: fetch(url, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            setSessionLifeTime();
            return response.json();
          })
          .catch((err) =>
            handleError(err, '016', 'GET_JOBS_CURRENT_DESCRIPTION'),
          ),
      });
    }

    case Jobs.GET_JOBS_FILES_IN_FULFILMENT: {
      const id = store.getState().jobs.currentJobId;
      const url = new URL(baseUrl + '/fulfilment_contents');
      const params = {
        fulfilment_id: id,
      };
      url.search = new URLSearchParams(params).toString();
      return next({
        type: Jobs.GET_JOBS_FILES_IN_FULFILMENT,
        payload: fetch(url, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            setSessionLifeTime();
            return response.json();
          })
          .catch((err) =>
            handleError(err, '017', 'GET_JOBS_FILES_IN_FULFILMENT'),
          ),
      });
    }

    case Auth.SIGN_OUT:
      return next({
        type: Auth.SIGN_OUT,
        payload: fetch(`${baseUrl}/auth/logout`, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then(() => {
            return signOutProcess(true);
          })
          .catch((err) => {
            const type = APP_CONSTANS.MESSAGING.NETWORK_ERROR.TYPE;
            const status = APP_CONSTANS.MESSAGING.STATUS.ERROR;
            const contetnt = APP_CONSTANS.MESSAGING.NETWORK_ERROR[status];
            store.dispatch(setMessage(type, status, contetnt));
          })
          .catch((err) => handleError(err, '018', 'SIGN_OUT')),
      });

    case Auth.GET_SYSTEM_PREFERENCE:
      const preferenceId = action.payload;
      return next({
        type: Auth.GET_SYSTEM_PREFERENCE,
        payload: fetch(
          `${newBaseUrl}/system_preferences/get?preference_id=${preferenceId}`,
          {
            headers: {
              Authorization: `Bearer ${store.getState().auth.token}`,
            },
          },
        )
          .then((response) => {
            return response.json();
          })
          .catch((err) => {
            const type = APP_CONSTANS.MESSAGING.NETWORK_ERROR.TYPE;
            const status = APP_CONSTANS.MESSAGING.STATUS.ERROR;
            const contetnt = APP_CONSTANS.MESSAGING.NETWORK_ERROR[status];
            store.dispatch(setMessage(type, status, contetnt));
          })
          .catch((err) => handleError(err, '039', 'SYSTEM_PREFERENCE/GET')),
      });

    case Auth.GET_SYSTEM_PREFERENCES_ALL:
      return next({
        type: Auth.GET_SYSTEM_PREFERENCES_ALL,
        payload: fetch(
          `${newBaseUrl}/system_preferences`,
          {
            headers: {
              Authorization: `Bearer ${store.getState().auth.token}`,
            },
          },
        )
          .then((response) => {
            return response.json();
          })
          .catch((err) => {
            const type = APP_CONSTANS.MESSAGING.NETWORK_ERROR.TYPE;
            const status = APP_CONSTANS.MESSAGING.STATUS.ERROR;
            const contetnt = APP_CONSTANS.MESSAGING.NETWORK_ERROR[status];
            store.dispatch(setMessage(type, status, contetnt));
          })
          .catch((err) => handleError(err, '043', 'SYSTEM_PREFERENCES')),
      });

    case Jobs.GET_JOBS_COMMENTS_IN_FULFILMENT: {
      const id = store.getState().jobs.currentJobId;
      const url = new URL(newBaseUrl + `/office/comment/fulfilment/${id}?internal=false&relative=true`);
      return next({
        type: Jobs.GET_JOBS_COMMENTS_IN_FULFILMENT,
        payload: fetch(url, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            setSessionLifeTime();
            return response.json();
          })
          .catch((err) =>
            handleError(err, '019', 'GET_JOBS_COMMENTS_IN_FULFILMENT'),
          ),
      });
    }

    case Jobs.GET_JOBS_REQUESTS_LIST: {
      const id = store.getState().jobs.currentJobId;
      const url = new URL(baseUrl + `/fulfilment_requests?fulfilment_id=${id}`);
      return next({
        type: Jobs.GET_JOBS_REQUESTS_LIST,
        payload: fetch(url, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            setSessionLifeTime();
            return response.json();
          })
          .catch((err) => handleError(err, '021', 'GET_JOBS_REQUESTS_LIST')),
      });
    }

    case Jobs.GET_ALL_COMPLICATIONS: {
      const url = new URL(baseUrl + '/complications');
      return next({
        type: Jobs.GET_ALL_COMPLICATIONS,
        payload: fetch(url, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            return response.json();
          })
          .catch((err) => handleError(err, '022', 'GET_ALL_COMPLICATIONS')),
      });
    }

    case Jobs.GET_ALL_COMPLICATIONS_CHILDREN: {
      const url = new URL(baseUrl + '/complication_children');
      return next({
        type: Jobs.GET_ALL_COMPLICATIONS_CHILDREN,
        payload: fetch(url, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            return response.json();
          })
          .catch((err) =>
            handleError(err, '023', 'GET_ALL_COMPLICATIONS_CHILDREN'),
          ),
      });
    }

    case Jobs.GET_COMPLICATION_CHILD: {
      const childId = action.payload;
      const url = new URL(baseUrl + `/complication_children/${childId}`);
      return next({
        type: Jobs.GET_COMPLICATION_CHILD,
        payload: fetch(url, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            return response.json();
          })
          .catch((err) => handleError(err, '024', 'GET_COMPLICATION_CHILD')),
      });
    }

    case Jobs.GET_JOBS_CURRENT_JOB_CHECK_POINTS: {
      const fulfilmentId = action.payload;

      const url = new URL(newBaseUrl + '/check_points');
      const params = { fulfilment_ids: fulfilmentId };
      url.search = new URLSearchParams(params).toString();

      return next({
        type: Jobs.GET_JOBS_CURRENT_JOB_CHECK_POINTS,
        payload: fetch(url, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            setSessionLifeTime();
            return response.json();
          })
          .catch((err) =>
            handleError(err, '041', 'GET_CURRENT_JOB_CHECK_POINTS'),
          ),
      });
    }

    case Jobs.GET_CURRENCIES: {
      const url = new URL(baseUrl + '/currencies?is_supported=true');
      return next({
        type: Jobs.GET_CURRENCIES,
        payload: fetch(url, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            return response.json();
          })
          .catch((err) =>
            handleError(
              err,
              '041_GET_JOBS_CURRENT_JOB_CHECK_POINTS',
              'GET_JOBS_CURRENT_JOB_CHECK_POINTS',
            ),
          ),
      });
    }

    case Jobs.GET_JOB_STATES: {
      const url = new URL(newBaseUrl + '/fulfilment_states');
      return next({
        type: Jobs.GET_JOB_STATES,
        payload: fetch(url, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            return response.json();
          })
          .catch((err) =>
            handleError(
              err,
              '042_GET_JOB_STATES',
              'GET_JOB_STATES',
            ),
          ),
      });
    }

    // PUT requests ---------------------------------------------------
    case User.PUT_USER_ACCOUNT: {
      const accountID = store.getState().user.userAccount.id;
      const params = {
        id: accountID,
        ...action.payload
      };
      const url = new URL(newBaseUrl + '/accounts');
      return next({
        type: User.PUT_USER_ACCOUNT,
        payload: fetch(url, {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
          body: JSON.stringify(params),
        })
          .then(() => {
            store.dispatch(getUserAccount());
            setSessionLifeTime();
          })
          .catch((err) => handleError(err, '035', 'PUT_USER_ACCOUNT', false)),
      });
    }

    // POST requests --------------------------------------------------

    case Jobs.SET_JOBS_ACCEPT_ORDER: {
      const currentJob = store.getState().jobs.currentJob;
      const url = new URL(baseUrl + '/engine/accept_order');
      const params = {
        order_id: currentJob.id,
      };
      return next({
        type: Jobs.SET_JOBS_ACCEPT_ORDER,
        payload: fetch(url, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
          body: JSON.stringify(params),
        })
          .then((response) => {
            if (response.status === 403) {
              response
                .json()
                .then((data) => data)
                .then((res) => {
                  const type = APP_CONSTANS.MESSAGING.UNAVAILABLE_DRONE.TYPE;
                  const status = APP_CONSTANS.MESSAGING.STATUS.ERROR;
                  const contetnt = res.details;
                  store.dispatch(setMessage(type, status, contetnt));
                });
            } else {
              return response.json();
            }
          })
          .then((data) => {
            if (data) {
              if (data.fulfilment_id) {
                store.dispatch(setCurrentJobId(data.fulfilment_id));
              }
              store.dispatch(getCurrentFulfilment());
              const type = APP_CONSTANS.MESSAGING.ACCEPT_ORDER.TYPE;
              const status = APP_CONSTANS.MESSAGING.STATUS.OK;
              const contetnt = APP_CONSTANS.MESSAGING.ACCEPT_ORDER[status];
              store.dispatch(setMessage(type, status, contetnt));
              setSessionLifeTime();
            }
            return data;
          })
          .catch((err) => {
            const type = APP_CONSTANS.MESSAGING.ACCEPT_ORDER.TYPE;
            const status = APP_CONSTANS.MESSAGING.STATUS.ERROR;
            const contetnt = APP_CONSTANS.MESSAGING.ACCEPT_ORDER[status];
            store.dispatch(setMessage(type, status, contetnt));
            handleError(err, '025a', 'SET_JOBS_ACCEPT_ORDER', false);
          }),
      });
    }

    case Jobs.SET_JOBS_DECLINE_CURRENT_FULFILMENT: {
      const currentJob = store.getState().jobs.currentJob;
      const url = new URL(newBaseUrl + '/engine/decline_fulfilment');
      const params = {
        fulfilment_id: currentJob.id,
      };
      return next({
        type: Jobs.SET_JOBS_DECLINE_CURRENT_FULFILMENT,
        payload: fetch(url, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
          body: JSON.stringify(params),
        })
          .then((response) => {
            const type = APP_CONSTANS.MESSAGING.DECLINE_FULFILMENT.TYPE;
            const status = APP_CONSTANS.MESSAGING.STATUS.OK;
            const contetnt = APP_CONSTANS.MESSAGING.DECLINE_FULFILMENT[status];
            store.dispatch(getUser());
            store.dispatch(setMessage(type, status, contetnt));
            setSessionLifeTime();
            return response.json();
          })
          .catch((err) => {
            const type = APP_CONSTANS.MESSAGING.DECLINE_FULFILMENT.TYPE;
            const status = APP_CONSTANS.MESSAGING.STATUS.ERROR;
            const contetnt = APP_CONSTANS.MESSAGING.DECLINE_FULFILMENT[status];
            store.dispatch(setMessage(type, status, contetnt));
            handleError(
              err,
              '025',
              'SET_JOBS_DECLINE_CURRENT_FULFILMENT',
              false,
            );
          }),
      });
    }

    case Jobs.SET_JOBS_REQUEST_TIME_FULFILMENT: {
      const requestTimeData = action.payload;
      const url = new URL(baseUrl + '/fulfilment_requests');
      return next({
        type: Jobs.SET_JOBS_REQUEST_TIME_FULFILMENT,
        payload: fetch(url, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
          body: JSON.stringify(requestTimeData),
        })
          .then((response) => {
            const type = APP_CONSTANS.MESSAGING.REQUEST_TIME_EXTENSION.TYPE;
            const status = APP_CONSTANS.MESSAGING.STATUS.OK;
            const contetnt =
              APP_CONSTANS.MESSAGING.REQUEST_TIME_EXTENSION[status];
            store.dispatch(setMessage(type, status, contetnt));
            setSessionLifeTime();
            setTimeout(() => store.dispatch(getFulfilmentRequests()), 100);
            return response.json();
          })
          .catch((err) => {
            const type = APP_CONSTANS.MESSAGING.REQUEST_TIME_EXTENSION.TYPE;
            const status = APP_CONSTANS.MESSAGING.STATUS.ERROR;
            const contetnt =
              APP_CONSTANS.MESSAGING.REQUEST_TIME_EXTENSION[status];
            store.dispatch(setMessage(type, status, contetnt));
            handleError(err, '026', 'SET_JOBS_REQUEST_TIME_FULFILMENT', false);
          }),
      });
    }

    case Jobs.SET_JOBS_CURRENT_JOB_SUBMIT: {
      const actionPayload = action.payload;
      const id = store.getState().jobs.currentJobId;
      const url = new URL(baseUrl + '/engine/submit_fulfilment');
      const params = {
        fulfilment_id: id,
        ...actionPayload?.force && {force: actionPayload.force},
        ...actionPayload?.comment && {comment: actionPayload.comment},
      };
      return next({
        type: Jobs.SET_JOBS_CURRENT_JOB_SUBMIT,
        payload: fetch(url, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
          body: JSON.stringify(params),
        })
          .then((response) => {
            const type = APP_CONSTANS.MESSAGING.SUBMIT_JOB.TYPE;
            const status = APP_CONSTANS.MESSAGING.STATUS.OK;
            const contetnt = APP_CONSTANS.MESSAGING.SUBMIT_JOB[status];
            store.dispatch(setMessage(type, status, contetnt));
            store.dispatch(getCurrentFulfilment());
            setSessionLifeTime();
            return response.json();
          })
          .catch((err) => {
            const type = APP_CONSTANS.MESSAGING.SUBMIT_JOB.TYPE;
            const status = APP_CONSTANS.MESSAGING.STATUS.ERROR;
            const contetnt = APP_CONSTANS.MESSAGING.SUBMIT_JOB[status];
            store.dispatch(setMessage(type, status, contetnt));
            handleError(err, '027', 'SET_JOBS_CURRENT_JOB_SUBMIT', false);
          }),
      });
    }

    case User.SET_USER_LEVEL_UPGRADE: {
      const upgradeData = action.payload;
      const url = new URL(baseUrl + '/user_requests');
      return next({
        type: User.SET_USER_LEVEL_UPGRADE,
        payload: fetch(url, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
          body: JSON.stringify(upgradeData),
        })
          .then((response) => {
            if (response.error) {
              const type = APP_CONSTANS.MESSAGING.LEVEL_UPGRADE.TYPE;
              const status = APP_CONSTANS.MESSAGING.STATUS.ERROR;
              const contetnt = APP_CONSTANS.MESSAGING.LEVEL_UPGRADE[status];
              store.dispatch(setMessage(type, status, contetnt));
            } else {
              const type = APP_CONSTANS.MESSAGING.LEVEL_UPGRADE.TYPE;
              const status = APP_CONSTANS.MESSAGING.STATUS.OK;
              const contetnt = APP_CONSTANS.MESSAGING.LEVEL_UPGRADE[status];
              store.dispatch(setMessage(type, status, contetnt));
              setTimeout(() => store.dispatch(getUserRequests()), 100);
              setSessionLifeTime();
              return response.json();
            }
          })
          .catch((err) =>
            handleError(err, '027a', 'SET_USER_LEVEL_UPGRADE', false),
          ),
      });
    }

    case User.SET_USER_COMMENT: {
      const commentData = action.payload;
      const url = new URL(newBaseUrl + '/office/comment');
      return next({
        type: User.SET_USER_COMMENT,
        payload: fetch(url, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
          body: JSON.stringify(commentData),
        })
          .then((response) => {
            setTimeout(() => store.dispatch(getCommentsInFulfilment()), 100);
            setSessionLifeTime();
            if (response.status !== 204) {
              return response.json();
            }
          })
          .catch((err) => handleError(err, '028', 'SET_USER_COMMENT', false)),
      });
    }

    case User.POST_USER_PREFS_NOTIFICATIONS: {
      const notificationsList = action.payload;
      let showMessage = true;
      if (typeof notificationsList === 'object') {
        notificationsList.hasOwnProperty(
          APP_CONSTANS.PHOTOGRAPHER_PROPS.LOCATION_FROM.CODE) ||
        notificationsList.hasOwnProperty(
          APP_CONSTANS.PHOTOGRAPHER_PROPS.JOB_LOOKUP_RADIUS) ?
        showMessage = false :
        showMessage = true;
      }
      const userID = store.getState().user.user.id;
      const params = [];
      for (const key in notificationsList) {
        params.push({
          preference_id: key,
          user_id: userID,
          value: `${notificationsList[key]}`,
        });
      }
      const url = new URL(baseUrl + '/user_preferences');
      return next({
        type: User.POST_USER_PREFS_NOTIFICATIONS,
        payload: fetch(url, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
          body: JSON.stringify(params),
        })
          .then((response) => {
            if (response.status >= 400 || response.status_code >= 400) {
              const type = APP_CONSTANS.MESSAGING.NOTIFICATIONS_CHANGE.TYPE;
              const status = APP_CONSTANS.MESSAGING.STATUS.ERROR;
              const contetnt =
                APP_CONSTANS.MESSAGING.NOTIFICATIONS_CHANGE[status];
              showMessage && store.dispatch(setMessage(type, status, contetnt));
            } else {
              const type = APP_CONSTANS.MESSAGING.NOTIFICATIONS_CHANGE.TYPE;
              const status = APP_CONSTANS.MESSAGING.STATUS.OK;
              const contetnt =
                APP_CONSTANS.MESSAGING.NOTIFICATIONS_CHANGE[status];
              store.dispatch(getUser());
              showMessage && store.dispatch(setMessage(type, status, contetnt));
              setSessionLifeTime();
              if (response.status !== 204) {
                return response.json();
              }
            }
          })
          .catch((err) =>
            handleError(err, '029', 'POST_USER_PREFS_NOTIFICATIONS', false),
          ),
      });
    }

    case User.POST_USER_PREF_IOS_APP_NOTIFY: {
      const preferenceId = action.payload;
      const userId = store.getState().user.user.id;
      const params = {
        preferences: [
          {
            preference_id: preferenceId,
            value: "true"
          }
        ],
        user_id: userId
      }
      const url = new URL(newBaseUrl + '/user_preferences/bulk');
      return next({
        type: User.POST_USER_PREF_IOS_APP_NOTIFY,
        payload: fetch(url, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
          body: JSON.stringify(params),
        })
          .then((response) => {
            if (response.status >= 400 || response.status_code >= 400) {
              const type = APP_CONSTANS.MESSAGING.NETWORK_ERROR.TYPE;
              const status = APP_CONSTANS.MESSAGING.STATUS.ERROR;
              const contetnt = APP_CONSTANS.MESSAGING.NETWORK_ERROR[status];
              store.dispatch(setMessage(type, status, contetnt));
            } else {
              setSessionLifeTime();
              if (response.status !== 204) {
                return response.json();
              }
            }
          })
          .catch((err) =>
            handleError(err, '044', 'POST_USER_PREF_IOS_APP_NOTIFY', false),
          ),
      });
    }

    case User.POST_ACCOUNT_PREFS: {
      const prefsList = action.payload;
      const accountID = store.getState().user.userClaims.aud;
      const params = [];
      for (const key in prefsList) {
        params.push({
          preference_id: key,
          account_id: accountID,
          value: `${prefsList[key]}`,
        });
      }
      const url = new URL(baseUrl + '/account_preferences');
      return next({
        type: User.POST_ACCOUNT_PREFS,
        payload: fetch(url, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
          body: JSON.stringify(params),
        })
          .then((response) => {
            const type = APP_CONSTANS.MESSAGING.PAYMENT_CHANGE.TYPE;
            const status = APP_CONSTANS.MESSAGING.STATUS.OK;
            const contetnt = APP_CONSTANS.MESSAGING.PAYMENT_CHANGE[status];
            store.dispatch(getUserAccountPrefs());
            store.dispatch(setMessage(type, status, contetnt));
            setSessionLifeTime();
            if (response.status !== 204) {
              return response.json();
            }
          })
          .catch((err) => handleError(err, '030', 'POST_ACCOUNT_PREFS', false)),
      });
    }

    case User.POST_USER_SHOW_ONBOARD: {
      const onboardId = action.payload;
      const userId = store.getState().user.user.id;
      const params = {
        onboard_id: onboardId,
        user_id: userId,
      };
      const url = new URL(baseUrl + '/onboard');
      return next({
        type: User.POST_USER_SHOW_ONBOARD,
        payload: fetch(url, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
          body: JSON.stringify(params),
        })
          .then((response) => (response ? response.json() : null))
          .catch((err) =>
            handleError(err, '031', 'POST_USER_SHOW_ONBOARD', false),
          ),
      });
    }

    case User.POST_USER_PURCHASE_METHOD: {
      const data = action.payload;
      const url = new URL(paymentApiUrl + '/purchase_methods');
      return next({
        type: User.POST_USER_PURCHASE_METHOD,
        payload: fetch(url, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
          body: JSON.stringify(data),
        })
          .then((response) => {
            message.success('Payment was successfully created!');
            setTimeout(() => store.dispatch(getPurchaseMethods()), 100);
            setTimeout(() => store.dispatch(getPurchaseMethodCount()), 100);
            setTimeout(() => store.dispatch(getUserAccount()), 100);
            setSessionLifeTime();
            return response ? response.json() : null;
          })
          .catch((err) =>
            handleError(err, '031_NEXT', 'POST_USER_PURCHASE_METHOD', false),
          ),
      });
    }

    case Jobs.SET_JOBS_READED_COMMENTS_IN_FULFILMENT: {
      const ids = action.payload;
      return next({
        type: Jobs.SET_JOBS_READED_COMMENTS_IN_FULFILMENT,
        payload: fetch(`${newBaseUrl}/office/comment/read`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
          body: JSON.stringify(ids),
        })
          .then((response) => {
            setTimeout(() => store.dispatch(getCommentsInFulfilment()), 100);
            if (response.status !== 204) {
              return response.json();
            }
          })
          .catch((err) =>
            handleError(
              err,
              '032',
              'SET_JOBS_READED_COMMENTS_IN_FULFILMENT',
              false,
            ),
          ),
      });
    }

    case Jobs.POST_JOBS_CURRENT_JOB_CHECK_IN: {
      const fulfilmentId = store.getState().jobs.currentJobId;
      const { latitude, longitude } = action.payload;
      if (!latitude) {
        logging('POST_JOBS_CURRENT_JOB_CHECK_IN', 'No latitude in Check-in')
      }
      if (!longitude) {
        logging('POST_JOBS_CURRENT_JOB_CHECK_IN', 'No longitude in Check-in')
      }

      const params = {
        fulfilment_id: fulfilmentId,
        latitude,
        longitude,
      };
      const url = new URL(newBaseUrl + '/office/photographer_check_in');
      return next({
        type: Jobs.POST_JOBS_CURRENT_JOB_CHECK_IN,
        payload: fetch(url, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
          body: JSON.stringify(params),
        })
          .then((response) => {
            const type = APP_CONSTANS.MESSAGING.CHECK_IN.TYPE;
            const status = APP_CONSTANS.MESSAGING.STATUS.OK;
            const content = APP_CONSTANS.MESSAGING.CHECK_IN[status];

            if (response && response.ok) {
              store.dispatch(setMessage(type, status, content));
              return response.json();
            }
          })
          .then(({ data }) => {
            if (data) {
              store.dispatch(setCurrentJobActiveCheckPoint(data));
            }
          })
          .catch((err) =>
            handleError(err, '040', 'POST_JOBS_CURRENT_JOB_CHECK_IN', false),
          ),
      });
    }

    case Jobs.POST_JOBS_CURRENT_JOB_CHECK_OUT: {
      const fulfilmentId = store.getState().jobs.currentJobId;
      const { latitude, longitude, checkPointId } = action.payload;

      if (!latitude) {
        logging('POST_JOBS_CURRENT_JOB_CHECK_OUT', 'No latitude in Check-out')
      }
      if (!longitude) {
        logging('POST_JOBS_CURRENT_JOB_CHECK_OUT', 'No longitude in Check-out')
      }

      const params = {
        check_id: checkPointId,
        latitude,
        longitude,
      };
      const url = new URL(newBaseUrl + '/office/photographer_check_out');
      return next({
        type: Jobs.POST_JOBS_CURRENT_JOB_CHECK_OUT,
        payload: fetch(url, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
          body: JSON.stringify(params),
        })
          .then((response) => {
            const type = APP_CONSTANS.MESSAGING.CHECK_OUT.TYPE;
            const status = APP_CONSTANS.MESSAGING.STATUS.OK;
            const content = APP_CONSTANS.MESSAGING.CHECK_OUT[status];

            if (response && response.ok) {
              store.dispatch(setMessage(type, status, content));
              store.dispatch(setCurrentJobActiveCheckPoint(null));
              store.dispatch(getCurrentJobCheckPoints(fulfilmentId));
              return response.json();
            }
          })
          .catch((err) =>
            handleError(err, '041', 'POST_JOBS_CURRENT_JOB_CHECK_OUT', false),
          ),
      });
    }

    // PATCH requests --------------------------------------------------

    case User.PATCH_USER_PREFS_CHANGE_PASSWORD: {
      const newPassword = action.payload.password;
      const newPasswordRepeat = action.payload.repeatPassword;
      const currentPassword = action.payload.currentPassword;
      const userID = store.getState().user.user.id;
      const params = {
        id: userID,
        current_password: currentPassword,
        password: newPassword,
        password_confirm: newPasswordRepeat,
      };
      const url = new URL(baseUrl + '/users/password');
      return next({
        type: User.PATCH_USER_PREFS_CHANGE_PASSWORD,
        payload: fetch(url, {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
          body: JSON.stringify(params),
        })
          .then((resp) => {
            if (resp.status === 500 || resp.status_code === 500) {
              const type = APP_CONSTANS.MESSAGING.PASSWORD_CHANGE.TYPE;
              const status = APP_CONSTANS.MESSAGING.STATUS.ERROR;
              const contetnt = APP_CONSTANS.MESSAGING.PASSWORD_CHANGE[status];
              store.dispatch(setMessage(type, status, contetnt));
            } else {
              const type = APP_CONSTANS.MESSAGING.PASSWORD_CHANGE.TYPE;
              const status = APP_CONSTANS.MESSAGING.STATUS.OK;
              const contetnt = APP_CONSTANS.MESSAGING.PASSWORD_CHANGE[status];
              store.dispatch(getUser());
              store.dispatch(setMessage(type, status, contetnt));
              setSessionLifeTime();
            }
          })
          .catch((err) =>
            handleError(err, '033', 'PATCH_USER_PREFS_CHANGE_PASSWORD', false),
          ),
      });
    }

    case User.PATCH_USER_ACCOUNT: {
      const accountID = store.getState().user.userClaims.aud;
      const params = {
        id: accountID,
        ...action.payload,
      };
      const url = new URL(baseUrl + '/accounts');
      return next({
        type: User.PATCH_USER_ACCOUNT,
        payload: fetch(url, {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
          body: JSON.stringify(params),
        })
          .then(() => {
            const type = APP_CONSTANS.MESSAGING.ACCOUNT_CHANGE.TYPE;
            const status = APP_CONSTANS.MESSAGING.STATUS.OK;
            const contetnt = APP_CONSTANS.MESSAGING.ACCOUNT_CHANGE[status];
            store.dispatch(getUser());
            store.dispatch(setMessage(type, status, contetnt));
            setSessionLifeTime();
          })
          .catch((err) => handleError(err, '034', 'PATCH_USER_ACCOUNT', false)),
      });
    }

    case User.PATCH_USER_ADDRESS: {
      const newAddress = action.payload;
      const url = new URL(baseUrl + '/user_addresses');
      return next({
        type: User.PATCH_USER_ADDRESS,
        payload: fetch(url, {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
          body: JSON.stringify(newAddress),
        })
          .then(() => {
            const type = APP_CONSTANS.MESSAGING.ADDRESS_CHANGE.TYPE;
            const status = APP_CONSTANS.MESSAGING.STATUS.OK;
            const contetnt = APP_CONSTANS.MESSAGING.ADDRESS_CHANGE[status];
            store.dispatch(getUser());
            store.dispatch(setMessage(type, status, contetnt));
            setTimeout(() => store.dispatch(getUserAddresses()), 100);
            setSessionLifeTime();
          })
          .catch((err) => handleError(err, '035', 'PATCH_USER_ADDRESS', false)),
      });
    }

    case User.PATCH_USER: {
      const newUserData = action.payload;
      const url = new URL(baseUrl + '/users');
      return next({
        type: User.PATCH_USER,
        payload: fetch(url, {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
          body: JSON.stringify(newUserData),
        })
          .then(() => {
            const type = APP_CONSTANS.MESSAGING.PROFILE_CHANGE.TYPE;
            const status = APP_CONSTANS.MESSAGING.STATUS.OK;
            const contetnt = APP_CONSTANS.MESSAGING.PROFILE_CHANGE[status];
            store.dispatch(setMessage(type, status, contetnt));
            setSessionLifeTime();
          })
          .catch((err) => handleError(err, '036', 'PATCH_USER', false)),
      });
    }

    // DELETE requests --------------------------------------------------

    case Jobs.DELETE_JOBS_CURRENT_FILE: {
      if (store.getState().jobs.deletedFileId) {
        const deletedFileId = store.getState().jobs.deletedFileId;
        const url = new URL(baseUrl + '/fulfilment_contents');
        const params = {
          id: deletedFileId,
        };
        url.search = new URLSearchParams(params).toString();
        return next({
          type: Jobs.DELETE_JOBS_CURRENT_FILE,
          payload: fetch(url, {
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${store.getState().auth.token}`,
            },
          })
            .then((response) => response.json())
            .catch((err) =>
              handleError(err, '037', 'DELETE_JOBS_CURRENT_FILE', false),
            ),
        });
      } else {
        break;
      }
    }

    case User.DELETE_USER_ACCOUNT: {
      const userId = store.getState().user.user.id;
      const url = new URL(baseUrl + '/cleaner/exclude_user');
      const userData = { id: userId };
      return next({
        type: User.DELETE_USER_ACCOUNT,
        payload: fetch(url, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
          body: JSON.stringify(userData),
        })
          .then(() => signOutProcess(true))
          .catch((err) =>
            handleError(err, '038', 'DELETE_USER_ACCOUNT', false),
          ),
      });
    }

    case User.DELETE_USER_PURCHASE_METHOD: {
      const paymentId = action.payload;
      const url = new URL(paymentApiUrl + '/purchase_methods/soft');
      const dataToBack = { id: paymentId };
      return next({
        type: User.DELETE_USER_PURCHASE_METHOD,
        payload: fetch(url, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
          body: JSON.stringify(dataToBack),
        })
          .then(() => {
            message.success('Payment was successfully deleted!');
            setTimeout(() => store.dispatch(getPurchaseMethods()), 100);
            setSessionLifeTime();
          })
          .catch((err) =>
            handleError(err, '039', 'DELETE_USER_PURCHASE_METHOD', false),
          ),
      });
    }
    // case Auth.GET_TOKEN: {
    //     const urlSearch = new URLSearchParams();
    //     urlSearch.append('username', '');
    //     urlSearch.append('password', '');
    //     const myHeaders = new Headers();
    //     myHeaders.append('accept', 'application/json');
    //     myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
    //     return next({
    //         type: Auth.GET_TOKEN,
    //         payload: fetch(baseUrl + '/auth/token', {
    //             method: 'POST',
    //             headers: myHeaders,
    //             body: urlSearch,
    //         }).then(response => response.json())
    //     })
    // };

    default:
      return next(action);
  }
};

export default httpGlobalFetch;
